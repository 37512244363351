<div class="alert alert-danger" role="alert" *ngIf="alipayStatus && alipayStatus !== 'approved'">
    Your Alipay transaction was declined. Try again using a different payment method.
</div>
<div class="card rounded-card mb-4 mt-2">
    <div class="card-body">
        <app-merchant-response></app-merchant-response>
        <form [formGroup]="billingInformation">
            <div class="row d-flex">
                <div class="ms-auto me-4">
                    <p class="float-end"> <span class="required-asterisk">*</span>Required</p>
                </div>
            </div>
            <h5 class="pb-2">Billing Information</h5>
            <ng-container *ngIf=" gd?.oscConfig?.displayBillingName">
                <app-billing-name-information formGroupName="nameDetails"
                    [orderData]="orderData"></app-billing-name-information>
            </ng-container>
            <ng-container *ngIf="gd?.oscConfig?.displayBillingAddress">
                <app-billing-address-information formGroupName="addressDetails"
                    [orderData]="orderData"></app-billing-address-information>
            </ng-container>
        </form>
        <div class="row mt-3 border-top pt-3">
            <div class="col-lg-4 d-flex">
                <button id="submit" [ngClass]="billingInformation.invalid ? 'disabled-bkg' : 'btn--primary'"
                    class="processing-button" [disabled]="billingInformation?.invalid" (click)="createAlipayPayment()">
                    <fa-icon [icon]="faLock"></fa-icon>
                    Process Payment
                </button>
            </div>
            <div class="col-lg-8 pt-4 sub-text">
                <div class="lock">
                    <fa-icon [icon]="faLock"></fa-icon>
                </div> ETS processes your payment with your security in mind. This may impact your experience.
            </div>
        </div>
    </div>
</div>
