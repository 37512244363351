<div *ngIf="loading">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise-fade">
        <p style="font-size: 20px; color: white">Loading <span aria-hidden="true">...</span></p>
    </ngx-spinner>
</div>
    <div *ngIf="isModeOfReceiptSelectNeeded" class="row justify-content-center py-5">
        <div class="col-md-6">
            <label for="app-mode-of-receipt" class="control-label"><span class="required-asterisk"
                    aria-label="required information">*</span>Please identify how you were contacted to help this customer: </label>
            <select id="app-mode-of-receipt" class="form-select form-select-md" name="app-mode-of-receipt" required
                (change)="onModeOfReceiptSelect($event)">
                <option value>-- Please Select --</option>
                <option value="F">Fax</option>
                <option value="I">In Person</option>
                <option value="M">Mail</option>
                <option value="T">Telephone</option>
                <option value="V">VRS</option>
            </select>
        </div>
    </div>

  <div *ngIf="!isOscSessionIdValid || !isTransactionValid" class="container">
    <div  class="row justify-content-center py-5">
      <div class="col-md-6">
        <label for="app-invalid-transaction" class="control-label">
          Sorry, but we cannot complete your payment because too much time has passed or there was another technical issue. Please return to the site where you were attempting to place your order.</label>
      </div>
    </div>
  </div>

    <div *ngIf="isShowPage()">
        <div class="container-fluid mt-4 mb-4">
            <div class="row">
                <div class="col-12">
                    <div class="align-items">
                        <img src="assets/img/bootstrap_chevron-left.svg" alt="<"/>
                        <a class="link" href="#" (click)="backToOrder();$event.preventDefault()"
                        style="color: #797979;font-size: 16px; font-family: Arial, Helvetica, sans-serif;margin-left: 4px;">Back to Order </a>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12">

                </div>
            </div>
            <div class="row">
                <div class='col-lg-8'>
                    <app-checkout [orderData]="orderDetails"></app-checkout>
                    <app-order-total [orderData]="orderDetails"></app-order-total>
                    <div class="select-payment-title">
                        <h3 tabindex="0"><span class="text__header_title">Select a payment method.</span></h3>
                        <app-order [paymentType]="paymentType" [contact]="contact" [paymentStatus]="paymentStatus"
                            [orderData]="orderDetails"></app-order>
                    </div>
                </div>
                <div class='col-lg-4 mb-4 '>
                    <app-order-info [orderData]="orderDetails"></app-order-info>
                </div>
            </div>
            <div class="row mt-4 mb-4">
                <div class="col-md-8">
                    <app-merchant-fields [merchantData]="orderDetails" [merchantConfigData]="merchantConfigData">
                    </app-merchant-fields>
                </div>
            </div>
        </div>
    </div>

