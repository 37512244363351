import { Component, Input } from '@angular/core';
import { Transaction } from '../models/transaction';

@Component({
  selector: 'app-order-total',
  templateUrl: './order-total.component.html',
  styleUrls: ['./order-total.component.css']
})
export class OrderTotalComponent {
  @Input() orderData: Transaction;
}
