<!-- payment redirect form -->
<app-merchant-response></app-merchant-response>


<div *ngIf="orderData && paypalEnabled"
       data-pp-message
       [attr.data-pp-amount]=orderData?.amount
       data-pp-style-text-size="14"
       data-pp-style-text-align="left"
      >
</div>

<div class="alipay-helptext" *ngIf="isAlipayEnabled()">
  <img class="alipay-helptext-logo" src="../../assets/img/AlipayPlus.png" alt="Alipay" />
  <span> Pay in your local currency using local e-wallets popular in many countries in Asia </span>
  <a (click)="openDialog()" class="learn-more">Learn more</a>
</div>
<div class="row">

<!-- credit card button -->
<div class="col-lg-3 pb-3" *ngIf="gd?.oscConfig?.ccPaymentEnabled">
<button id="btn-credit-card" arial-label="Credit Card" aria-controls="creditCard" class="btn btn-primary"
  [class.changestyle]="activeBtn" [disabled]="status && status === 'approved'"
  (click)="loadPaymentIframe();">
  <fa-icon [icon]="faCreditCard"></fa-icon> Credit Card
</button>
</div>
<!-- paypal button -->
<div class="col-lg-3 pb-3" *ngIf="orderData && isPaypalEnabled()" >
<button id="btn-paypal-fiserv" aria-controls="payPal" class="btn btn-primary" [class.changestyle]="activePP"
  [disabled]="status && status === 'approved'" (click)="loadPaypal();">
  <fa-icon [icon]="faPaypal"> </fa-icon> PayPal
</button>
</div>
<!-- telecheck button -->
<div class="col-lg-3 pb-3" *ngIf="orderData && isBankAccountEnabled()" >
<button id="btn-check" aria-controls="bankAccount" class="btn btn-primary" [class.changestyle]="activeBA"
  [disabled]="status && status === 'approved'"(click)="loadBankAccount();">
  <fa-icon [icon]="faLandmark"></fa-icon> Bank Account
</button>
</div>
<!-- alipay button -->
<div class="col-lg-3 pb-3" *ngIf="isAlipayEnabled()">
  <button
    title="Click here to be presented with the option to pay using one of several local e Wallets preferred by consumers in China, Hong Kong, Indonesia, Malaysia, the Philippines, South Korea, and Thailand."
    id="alipay-btn" aria-controls="alipay" class="btn btn-primary btn-padding alipay-plus" [class.changestyle]="activeAP"
    [disabled]="status && status === 'approved'" (click)="loadAlipay();">
    <img *ngIf="!activeAP" class="alipay" src="../../assets/img/AlipayPlus.png" alt="Alipay+" />
    <img *ngIf="activeAP" class="alipay-white" src="../../assets/img/AlipayWhite.png" alt="Alipay+" />
    <img *ngIf="!activeAP" class="alipay-white alipay-hover" src="../../assets/img/AlipayWhite.png" alt="Alipay+" />
  </button>
</div>
<!-- rendered credit card section -->
<div id="creditCard" *ngIf='showCreditCard'>
  <app-credit-card [billingDetails]="billingDetails" [status]="status"
    [disabledPaymentMethodNames]="getBlockedPaymentMethodNames()" [orderData]="orderData"></app-credit-card>
</div>
<!-- rendered telecheck section -->
<div id="bankAccount" *ngIf='showBankAccount'>
  <app-telecheck [orderData]="orderData"></app-telecheck>
</div>
<!-- rendered alipay section -->
<div id="alipay" *ngIf='showAlipay'>
  <app-alipay [alipayStatus] ="alipayStatus" [orderData]="orderData"></app-alipay>
</div>
<!-- rendered paypal section -->
<div id="payPal" *ngIf='showPaypal && !gd?.isStubEnabled'>
  <app-paypal></app-paypal>
</div>
<!-- rendered paypal stub section -->
<div id="payPal" *ngIf='showPaypal && gd?.isStubEnabled' aria-hidden="true">
  <app-paypal-stub></app-paypal-stub>
</div>
</div>
<app-accepted-payment-types [paypalEnabled]="paypalEnabled" [alipayEnabled]="alipayEnabled"  [disabledPaymentMethodNames]="getBlockedPaymentMethodNames()"></app-accepted-payment-types>
