
<div class="tooltiptext" (click)="loadingTooltip()">
    <img src="../../assets/svg/cvvtip.svg" />
    <span class="tooltiptextcvv"></span>
</div>

<div id="tooltip_modal" class="w3-modal">
    <div class="w3-modal-content">
        <div *ngIf="!oscCodes.includes(gd.oscConfig.oscCode)" class="modal_content__tooltip">
            <div class="tooltip__cvv">
                <img src="../../assets/svg/cvv_tooltip_modal.svg" alt="tooltip" />
                <span class="tooltip_cvv_title">CVV</span>
            </div>
            <span (click)="closeModal()" class="w3-display-topright">
                <img src="../../assets/svg/close_modal.svg" alt="close" />
            </span>
        </div>
        <div *ngIf="oscCodes.includes(gd.oscConfig.oscCode)" class="modal_content__tooltip_toefl">
            <div class="tooltip__cvv">
                <img src="../../assets/svg/cvv_tooltip_modal.svg" alt="tooltip" />
                <span class="tooltip_cvv_title">CVV</span>
            </div>
            <span (click)="closeModal()" class="w3-display-topright">
                <img src="../../assets/svg/close_modal.svg" alt="close" />
            </span>
        </div>
        <div class="w3-container">
            <div class="cvvtooltip__title">
                <h6 [ngClass]="oscCodes.includes(gd?.oscConfig?.oscCode) ? 'cvvtooltip_title__headr_ert' : 'cvvtooltip_title__headr'">
                    Visa, MasterCard and Discover Cards
                </h6>
                <span [ngClass]="oscCodes.includes(gd.oscConfig.oscCode) ? 'text__tooltip_info_ert' : 'text__tooltip_info'">
                    The CVV (Card Verification Value) number is the 3-digit value printed on the signature panel on the back of Visa, MasterCard and Discover Cards immediately following the credit card account number.
                </span>
            </div>
            <div class="cvvtooltip_rear_side">
                <img src="../../../assets/img/cvv_rearside.png" alt="cvv card" />
            </div>

            <div class="cvvtooltip__title">
              <h6 [ngClass]="oscCodes.includes(gd.oscConfig.oscCode) ? 'cvvtooltip_title__headr_ert' : 'cvvtooltip_title__headr'">
                American Express Cards
              </h6>
              <span [ngClass]="oscCodes.includes(gd.oscConfig.oscCode) ? 'text__tooltip_info_ert' : 'text__tooltip_info'"> The CID (card identification) 
                number is the 4-digit, non-embossed number printed above your account number on the face of your card.</span>
            </div>
            <div class="cvvtooltip_rear_side">
              <img src="../../../assets/img/cvv_card_front.png" alt="cvv card" />
          </div>
        </div>
    </div>
</div>
