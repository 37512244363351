<footer class="pt-4 pb-4 footer-align">
    <div class="container-fluid footer-text-align">
      <small>Copyright &copy; 2024 by ETS. All trademarks are the property of their respective owners.</small>
      <div class="mb-2">
        <ul>
          <li><a id="#footer" href="https://www.ets.org/legal" target="_blank"><span class="line__font_text">Legal</span></a></li>
          <li><span>|</span></li>
          <li><a href="https://www.ets.org/legal/privacy" target="_blank"><span class="line__font_text">Privacy and Security</span></a></li>
          <li><span>|</span></li>
          <li><a href="https://www.ets.org/legal/trademarks/owned" target="_blank"><span class="line__font_text">ETS Trademarks</span></a></li>
        </ul>
      </div>
    </div>
</footer>
