import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[appUnicodeValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: UnicodeValidatorDirective,
        multi: true
    }]
})
/**
* Summary. Validate the pattern with unicode
*
* Below is an explanation of Email regex
* [^@!#$%&'*+\-\/=?\^_`{|}\\\[\]"<>(),:;] -- not allowed first chars in group
* .[^\s]{1,63} -- allow any chars without space with max length 63
* @.[^\s]{2,253} -- @ allowed any chars in domain name without space with max length 253 followed by dot .
* .{1,3}[^.@!#$%&'*+-\/=?\^_`\{|\}\\\[\]"<>(),:;] -- after domain the first dot can repeat n number of times with max 4 any chars not allowing chars in set
* /gu -- g=global, u=unicode
*  
*/

export class UnicodeValidatorDirective implements Validator {
    @Input() appUnicodeValidator: string;
    validate (control: AbstractControl): { [key: string]: any } {
        let regEx = new RegExp(null);
        if (!control.value) {
            return null;
        }
        if (this.appUnicodeValidator === 'name') {
            regEx = /^[_A-z]*((-|\s)*[_A-z\p{L}])*$/gu;
        } else if (this.appUnicodeValidator === 'address') {
            regEx = /^[_A-z0-9\s]*([_A-z0-9\s\p{L}])*$/gu;        
        } else if (this.appUnicodeValidator === 'email') {
            regEx = /^(?=.{5,254}$)([^@!#$%&'*+-\/=?\^_`\{|\}\\\[\]"<>(),:;][\S]*)@([\S]+\.[^\s@!#$%&'*+-\/=?\^_`\{|\}\\\[\]"<>(),:;]{2,})$/gu;
        }
        const valid  = regEx.test(control.value);
        return valid ? null : { 'inValidUnicode': true };
    }  
}