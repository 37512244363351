import {Component} from '@angular/core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { GlobalAppData } from '../models/global-app-data';
import { Constants } from '../constants/constants';

@Component({
  selector: 'app-cvv-tooltip',
  templateUrl: './cvv-tooltip.html',
  styleUrls: ['./cvv-tooltip.css']
})
export class NgbdTooltipCustomclass {
    faQuestionCircle = faQuestionCircle

    hoverTooltip:string = ''

    oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;
    
    constructor(public gd:GlobalAppData) {
    }

    ngOnInit(): void {
      console.log(' [ Tooltip val  ] ',this.hoverTooltip)
    }

    loadingTooltip(){
      document.getElementById('tooltip_modal').style.display = 'block'
    }
    openModal(){
      document.getElementById('exampleModal').style.display = 'block'
    }
    closeModal(){
      document.getElementById('tooltip_modal').style.display='none';
    }

  }
