import { Component, Input, ViewChild } from '@angular/core';
import { MerchantResponseComponent } from '../merchant-response/merchant-response.component';
import { Transaction } from '../models/transaction';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent {
  @Input() orderData: Transaction;
  @ViewChild(MerchantResponseComponent) public merchantResponseComponent;

  constructor() {
  }

  modifyYourOrder() {
    this.merchantResponseComponent.backToOrder('backToOrder', '', {});
  }
}
