import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-alipay-learn-more',
  templateUrl: './alipay-learn-more.component.html',
  styleUrls: ['./alipay-learn-more.component.css']
})
export class AlipayLearnMoreDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AlipayLearnMoreDialogComponent>){
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }
  exit() {
    this.dialogRef.close();
  }
}
