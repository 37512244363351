<div class="card rounded-card mb-4 mt-2">
  <!-- payment redirect form -->
  <app-merchant-response></app-merchant-response>
  <div class="alert alert-danger" role="alert" *ngIf="paymentStatus && paymentStatus !== 'approved'">
    {{errorMessage}}
  </div>
  <div class="card-body">
    <form id="ccForm" name="ccForm" (ngSubmit)="ccFormData.form.valid" #ccFormData="ngForm" aria-label="Pay with credit card form">
      <div class="row d-flex">
        <div class="ms-auto me-4">
          <p class="float-end"> <span class="required-asterisk">*</span>Required</p>
        </div>
      </div>
      <!-- credit card type allowd drop down list -->
      <div class="row">
        <div class="col-md-3">
          <label for="cc-option" class="control-label"><span class="required-asterisk"
                                                             aria-label="required information">*</span>Card Type: </label>
          <select id="cc-option" class="form-select form-select-md" name="cardType" required="true" [(ngModel)]="model.cardType" #cardType="ngModel" (change)="onCardTypeChange(cardType.value)">
            <option value="001" *ngIf="gd?.oscConfig?.visaEnabled && showCreditcardType('Visa')">Visa</option>
            <option value="002" *ngIf="gd?.oscConfig?.masterCardEnabled && showCreditcardType('MasterCard')">MasterCard</option>
            <option value="003" *ngIf="gd?.oscConfig?.amexEnabled && showCreditcardType('Amex')">American Express</option>
            <option value="007" *ngIf="gd?.oscConfig?.jcbEnabled && showCreditcardType('JCB')">JCB</option>
            <option value="004" *ngIf="gd?.oscConfig?.discoverEnabled && showCreditcardType('Discover')">Discover</option>
            <option value="006" *ngIf="gd?.oscConfig?.unionPayEnabled && showCreditcardType('UnionPay')">Union Pay</option>
            <option value="005" *ngIf="gd?.oscConfig?.dinersClubEnabled && showCreditcardType('Diners Club')">
              Diners Club International</option>
            <option value="008" *ngIf="gd?.oscConfig?.rupayGlobalEnabled && showCreditcardType('RuPay Global')">RuPay Global</option>
          </select>
          <div *ngIf="cardType.invalid && (cardType.dirty || cardType.touched)" class="tip" role="alert">
            <div *ngIf="cardType.errors.required">You must select your Credit Card type.</div>
          </div>
        </div>
      </div>
      <!-- Creditcard fields for Fiserv -->
      <div class="row mt-3" *ngIf="!isStubEnabled">
        <div class="col-lg-6 col-md-12">
          <label for="cc-card" class="control-label"><span class="required-asterisk">*</span>Card Number</label>
          <div id="cc-card" class="form-controls payment-fields disabled empty" minlength="16" maxlength="19" data-cc-card></div>
        </div>
        <div class="col-lg-2 col-md-3">
          <label for="cc-expiry" class="control-label"><span class="required-asterisk">*</span>Exp Date</label>
          <div id="cc-expiry" class="form-controls payment-fields disabled empty" aria-label="expiration date" data-cc-exp></div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6" *ngIf="showCvvForCC&&isShowCvv()">
          <label for="cc-cvv" class="control-label"><span class="required-asterisk">*</span>CVV
            <app-cvv-tooltip></app-cvv-tooltip></label>
          <div class="form-controls payment-fields disabled empty" aria-label="CVV Number is a three- or four-digit number printed on the back or front of credit cards." aria-describedby="cvv-tip" id="cc-cvv" data-cc-cvv></div>
        </div>
      </div>
      <div class="row" *ngIf="!isStubEnabled">
        <div class="col-lg-6">
          <label for="cc-name" class="control-label"><span class="required-asterisk">*</span>Card Holder Name</label>
          <div class="form-controls payment-fields disabled empty" id="cc-name" aria-label="Card Holder Name" data-cc-name></div>
        </div>
      </div>
      <!-- Creditcard fields for stubing -->
      <div class="row mt-3" *ngIf="!!isStubEnabled">
        <div class="col-lg-6 col-md-12" *ngIf="selectedCard === '003'; else  ccsixteen">
          <label for="stubCardNumber"><span class="required-asterisk">*</span>Card Number </label>
          <input id="stubCardNumber" class="form-control form-control-lg" aria-label="credit card number" name="stubCardNumber" required pattern="^[0-9]+$" minlength="15" maxlength="19" ngModel #stubCardNumber="ngModel">
          <div *ngIf="stubCardNumber.invalid && (stubCardNumber.dirty || stubCardNumber.touched)" class="tip" role="alert" aria-describedby="card-number">
            <div *ngIf="stubCardNumber.errors?.minlength">Card number must be at least 15 characters long.</div>
            <div *ngIf="stubCardNumber.errors?.required">You must enter a valid card number.</div>
            <div *ngIf="stubCardNumber.errors?.pattern">Please do not use any embedded spaces, hyphens, or special characters.</div>
          </div>
        </div>
        <ng-template #ccsixteen>
          <div class="col-lg-6 col-md-12">
            <label for="stubCardNumber"><span class="required-asterisk">*</span>Card Number </label>
            <input id="stubCardNumber" class="form-control form-control-lg" aria-label="credit card number" name="stubCardNumber" required pattern="^[0-9]+$" minlength="16" maxlength="19" ngModel #stubCardNumber="ngModel">
            <div *ngIf="stubCardNumber.invalid && (stubCardNumber.dirty || stubCardNumber.touched)" class="tip" role="alert" aria-describedby="stubCardNumber">
              <div *ngIf="stubCardNumber.errors?.minlength">Card number must be at least 16 characters long.</div>
              <div *ngIf="stubCardNumber.errors?.required">You must enter a valid card number.</div>
              <div *ngIf="stubCardNumber.errors?.pattern">Please do not use any embedded spaces, hyphens, or special characters.</div>
            </div>
          </div>
        </ng-template>
        <div class="col-lg-2 col-md-3">
          <label for="stubExpDate"><span class="required-asterisk">*</span>Exp Date</label>
          <input id="stubExpDate" class="form-control form-control-lg" aria-label="expiration date" name="stubExpDate" pattern="^[0-9/]+$" minlength="5" maxlength="5" ngModel #stubExpDate="ngModel" placeholder="MM/YY" required>
          <div *ngIf="stubExpDate.invalid && (stubExpDate.dirty || stubExpDate.touched)" class="tip" role="alert" aria-describedby="stubExpDate">
            <div *ngIf="stubExpDate.errors?.required">You must enter card expiration month.</div>
            <div *ngIf="stubExpDate.errors?.pattern">Please do not use any embedded spaces, hyphens, or special characters.</div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6" *ngIf="showCvvForCC&&isShowCvv()">
          <label for="stubCvv"><span class="required-asterisk">*</span>CVV
            <app-cvv-tooltip></app-cvv-tooltip></label>
          <input id="stubCvv" class="form-control form-control-lg" aria-label="CVV Number is a three- or four-digit number printed on the back or front of credit cards." name="stubCvv" pattern="^[0-9]+$" minlength="3" maxlength="4" ngModel data-cc-cvv #stubCvv="ngModel" required>
          <div *ngIf="stubCvv.invalid && (stubCvv.dirty || stubCvv.touched)" class="tip" role="alert" aria-describedby="stubCvv">
            <div *ngIf="stubCvv.errors?.minlength">Card cvv number must be at least 3 characters long.</div>
            <div *ngIf="stubCvv.errors?.required">You must enter card cvv number.</div>
            <div *ngIf="stubCvv.errors?.pattern">Please do not use any embedded spaces, hyphens, or special characters.</div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!!isStubEnabled">
        <div class="col-lg-6 mb-3">
          <label for="stubCcName"><span class="required-asterisk">*</span>Card Holder Name</label>
          <input id="stubCcName" class="form-control form-control-lg" name="stubCcName" required aria-label="Card Holder Name" ngModel #chName="ngModel" maxlength="96">
          <div *ngIf="chName.invalid && (chName.dirty || chName.touched)" class="tip" role="alert" aria-describedby="stubCcName">
            <div *ngIf="chName.errors.required">Card Holder Name is required.</div>
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-2 pe-2">
        <strong>Billing Address</strong>
      </div>
      <!-- Billing address section -->
      <form [formGroup]="billingInformation">
        <app-billing-address-information [orderData]="orderData" [requiredFields]="getRequiredFields()"
                                         [addrLabel]="'Same as Shipping/Contact Information'"
                                         formGroupName="addressDetails"></app-billing-address-information>
      </form>

      <div class="row mt-4  pe-2 ps-2">
        <div class="col-md-12 border-top">
          <small>Depending on the credit card account number entered, you may be given the
            option to select a currency after selecting "Process Payment."</small>
        </div>
      </div>
      <div class="row mt-3 ">
        <div class="col-4 d-flex">
          <div id="payButton" *ngIf="!isStubEnabled">
            <!-- Button to submit the data to fiserv -->
            <ng-container *ngIf="ccFormData?.invalid || payButton?.disabled">
              <button id="submit" [ngClass]="ccFormData.invalid || payButton?.disabled ? 'disabled-bkg' : 'btn--primary'"
                      disabled
                      data-submit-btn >
                <fa-icon [icon]="faLock"></fa-icon>
                          Process Payment
              </button>
            </ng-container>

            <ng-container *ngIf="!(ccFormData?.invalid || payButton?.disabled)">
              <button id="submit" [ngClass]="ccFormData.invalid || payButton?.disabled || !isValidForVisa() ? 'disabled-bkg' : 'btn--primary'"
                      [attr.disabled]="ccFormData.invalid ||!isValidForVisa() ? 'disabled' : null"
                      data-submit-btn >
                <fa-icon [icon]="faLock"></fa-icon>
                          Process Payment
              </button>
            </ng-container>


          </div>
          <div id="stubPayButton" *ngIf="isStubEnabled">
            <!-- Button to submit the data to stubing -->
            <button type="button" [ngClass]="ccFormData.invalid || !isValidForVisa()? 'disabled-bkg' : 'btn--primary'"
                    [attr.disabled]="ccFormData.invalid || !isValidForVisa()? 'disabled' : null" (click)="submitStubForm(ccFormData)"><span
              *ngIf="ccFormData.invalid || !isValidForVisa()">
                                <fa-icon [icon]="faLock"></fa-icon>
                            </span> Process Payment </button>
          </div>
        </div>
        <div class="col-8 sub-text">
          <div class="lock">
            <fa-icon [icon]="faLock"></fa-icon>
          </div> ETS processes your payment with your security in mind. This may impact your experience.
          <a tabindex="0" role="link" class="link link-color" (click)="openLearnMore(dsmodal)">Learn more.</a>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- 3DS form -->
<div [style.display]="'none'" class="secure-3ds-form-container" *ngIf="secure3dsFormData">
  <div [innerHtml]="secure3dsFormData"></div>
</div>
<!-- ACS Form -->
<div [style.display]="'none'" class="auth-challenge-container" *ngIf="authChallengeParams">
  <form id="epms-acs-redirect-form" ngNoForm method="POST" action={{authChallengeParams.acsURL}} #authChallengeForm>
    <div *ngIf="authChallengeParams.payerAuthenticationRequest">
      <input type="hidden" name="PaReq" value="{{authChallengeParams.payerAuthenticationRequest}}" />
      <input type="hidden" name="MD" value="{{authChallengeParams.merchantData}}" />
    </div>
    <div *ngIf="authChallengeParams.creq">
      <input type="hidden" name="creq" value="{{authChallengeParams.creq}}" />
      <input type="hidden" name="threeDSSessionData" value="{{authChallengeParams.sessionData}}" />
    </div>
    <input type="hidden" name="TermUrl" value="{{authChallengeParams.termURL}}" />
  </form>
</div>
<!-- DCC Confirmation-->
<ng-template #dccModal let-modal>
  <div class="modal-header">
    <h4 class="amt-header">Select Your Choice of Currency</h4>
  </div>
  <div class="inner-modal">
    <table class="dcc amt-labels" >
      <tr>
        <td>
          <div class="flag pe-4"><img class="img-fluid" src="../../assets/img/u3579.png" alt="US Flag" />
          </div>
        </td>
        <td>
          <div class="amount"><span class="medium">{{dccResponse.currency}} </span>
            <span class="medium">{{dccResponse.grandTotalAmount}}</span></div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="flag pe-4"><img class="img-fluid" src="../../assets/img/{{dccResponse.foreignCurrency}}.png" alt="{{dccResponse.foreignCurrency}} Flag" /> </div>
        </td>
        <td>
          <div class="amount"><span class="medium">{{dccResponse.foreignCurrency}} </span>
            <span class="medium"> {{dccResponse.foreignCurrencyGrandTotalAmount}}</span> <br />
            <span class="medium">1 {{dccResponse.currency}} = {{dccResponse.exchangeRate}}
              {{dccResponse.foreignCurrency}} Includes
              {{dccResponse.marginRatePercentage}}% margin</span></div>
        </td>
      </tr>
    </table>
    <div class="d-flex justify-content-between">
      <button class="btn--primary amt-btn " (click)="exitdcc(false);modal.close()">Pay in {{dccResponse.currency}}</button>
      <button class="btn--primary amt-btn" (click)="exitdcc(true);modal.close()">Pay in
        {{dccResponse.foreignCurrency}}</button>
    </div>
  </div>
</ng-template>
<!-- Learn more modal section -->
<ng-template #dsmodal let-modal>
  <div class="modal-header">
    <h4 tabindex="0">
      <fa-icon class="question-3ds" [icon]="faQuestionCircle"></fa-icon> 3DS Information
    </h4>
    <button type="button" (click)="modal.close()" class="btn-close btn-close-white me-2" aria-label="Close"></button>
  </div>
  <div class="inner-modal">
    <p>Visa Secure/Verified by Visa, MasterCard SecureCode, American Express SafeKey, and Discover ProtectBuy are 3D Secure (3DS) online fraud prevention measures used by card companies as an added layer of security to protect cardholder account information.
      During the payment process you may be asked by your card issuing bank to verify cardholder identity by entering an authentication code known only to the cardholder and the bank. Failure to enter the required information or entry of invalid
      information will result in the sale being rejected. If you don’t know your 3D-Secure passcode or password, and you are not being given the option to enroll online in the bank’s pop up screen, then you will need to contact your bank.
    </p>
    <div class="icons d-flex justify-content-between">
      <div class="credit-card">
        <img class="img-fluid" src="../../assets/img/secure-120x120-visa-secure.png" alt="Visa Secure" />
      </div>
      <div class="credit-card">
        <img class="img-fluid" src="../../assets/img/secure-120x120-mc-securecode.png" alt="MasterCard SecureCode" />
      </div>
      <div class="credit-card">
        <img class="img-fluid" src="../../assets/img/secure-120x120-amex-safekey.png" alt="American Express SafeKey" />
      </div>
      <div class="credit-card">
        <img class="img-fluid" src="../../assets/img/secure-120x120-discover-protectbuy.png" alt="Discover Protect Buy" />
      </div>
    </div>
  </div>
</ng-template>
