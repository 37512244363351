<form [formGroup]="form">
  <div class="row">
    <div class="col-lg-12 pb-3" *ngIf="gd?.oscConfig?.displayShpngAsBillngNam">
      <input type="checkbox" id="sameAsShippingContactName" name="sameAsShippingContactName"
        formControlName="sameAsShippingContactName" (change)="sameNamecheckbox($event)" />
      <label class="ps-2" for="sameAsShippingContactName">Same as Shipping/Contact name</label>
    </div>
    <div class="col-lg-4 pb-3">
      <label for="firstName"><span class="required-asterisk">*</span>First Name</label>
      <input id="firstName" name="firstName" class="form-control form-control-md" formControlName="firstName"
        placeholder="First name" />
      <span *ngIf="
      (form.controls['firstName'].hasError('required') || form.controls['firstName'].hasError('minLength')) &&
      form.controls['firstName'].touched
    " class="text-danger">
        You must enter First name.</span>
    </div>
    <div class="col-lg-4 pb-3">
      <label for="lastName"><span class="required-asterisk">*</span>Last Name</label>
      <input id="lastName" name="lastName" class="form-control form-control-md" formControlName="lastName"
        placeholder="Last name" />
        <span *ngIf="
      (form.controls['lastName'].hasError('required') || form.controls['lastName'].hasError('minLength')) &&
      form.controls['lastName'].touched
    " class="text-danger">
    You must enter Last name.</span>
    </div>
  </div>
</form>