import { Component, Input } from '@angular/core';
import { GlobalAppData } from '../models/global-app-data';

@Component({
  selector: 'app-accepted-payment-types',
  templateUrl: './accepted-payment-types.component.html',
  styleUrls: ['./accepted-payment-types.component.css']
})
export class AcceptedPaymentTypesComponent {
  @Input() paypalEnabled: boolean;
  @Input() alipayEnabled: boolean;
  @Input('disabledPaymentMethodNames') disabledPaymentMethodNames = [];
  constructor(public gd: GlobalAppData) {
  }

  showCreditcardType(val: string): boolean {
    return this.disabledPaymentMethodNames.indexOf(val) === -1;
  }
}

