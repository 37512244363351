<div class="accepted_logos">
    <div class="accepted_title">
        <small class="accepted_title__header font_family__standard">Accepted Payment Types</small>
    </div>
    <main class="accpt_pymnt__types">
        <div class="align__payment_types_acptnc" *ngIf="gd?.oscConfig?.ccPaymentEnabled">
            <div *ngIf="gd?.oscConfig?.visaEnabled && showCreditcardType('Visa')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsvisa-copy.png" alt="Visa" />
            </div>
            <div *ngIf="gd?.oscConfig?.masterCardEnabled && showCreditcardType('MasterCard')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsmastercard-copy.png" alt="MasterCard" />
            </div>
            <div *ngIf="gd?.oscConfig?.amexEnabled && showCreditcardType('Amex')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsamex-copy.png" alt="American Express" />
            </div>
            <div *ngIf="gd?.oscConfig?.discoverEnabled && showCreditcardType('Discover')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsdiscover-copy.png" alt="Discover" />
            </div>
            <div *ngIf="gd?.oscConfig?.jcbEnabled && showCreditcardType('JCB')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsjcb-copy.png" alt="JCB" />
            </div>
            <div *ngIf="gd?.oscConfig?.unionPayEnabled && showCreditcardType('UnionPay')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsunionpay-copy.png" alt="UnionPay" />
            </div>
            <div *ngIf="gd?.oscConfig?.dinersClubEnabled&& showCreditcardType('Diners Club')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsdinersclub-copy.png" alt="Diners Club" />
            </div>
            <div *ngIf="gd?.oscConfig?.rupayGlobalEnabled && showCreditcardType('RuPay Global')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsrupay-copy.png" alt="RuPay" />
            </div>
            <div *ngIf="paypalEnabled">
                <!-- PayPal Logo -->
                <img class="pymnt_cc_amex_logo paypal_logo" src="../../assets/img/PP_Acceptance_Marks_for_LogoCenter_150x94.png" alt="Paypal" />
                <!-- PayPal Logo -->
            </div>
            <!-- <div class="col-md-1 col-2 d-flex justify-content-center ms-2 pt-3 text-nowrap Alipay-Acceptance" *ngIf="alipayEnabled">
          ....& more
          </div> -->
        </div>
        <div *ngIf="alipayEnabled" class="alipay-con">
            <img class="pymnt_cc_amex_logo__alipay" src="../../assets/img/AlipayPlusAcceptance.png" alt="Alipay+" />
        </div>
    </main>
</div>
