<div class="modal-header">
    <h2 mat-dialog-title>Alipay+</h2>
    <button mat-icon-button class="close-button" (click)="exit()">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <p>After clicking on the Alipay+ payment button, you will be presented with the option to pay in your local currency
        using any one of many popular local e-wallets preferred by customers in China, Hong Kong, Indonesia, Malaysia,
        the Philippines, South Korea, and Thailand including but not limited to:
    </p>
    <ul style="list-style-type: none">
        <li>AlipayChina (China)</li>
        <li>AlipayHK (Hong Kong, SAR)</li>
        <li>KaKao Pay (South Korea)</li>
        <li>Kredivo (Indonesia)</li>
        <li>Gcash (The Philippines)</li>
        <li>Touch'n Go (Malaysia)</li>
        <li>Rabbit Line Pay (Thailand)</li>
        <li>Truemoney (Thailand)</li>
        <li>Dana (Indonesia)</li>
        <li>Akulaku Paylater (Indonesia)</li>
        <li>Akulaku Paylater (Philippines)</li>
    </ul>
    <p>Support for additional e-wallets, payment methods and countries may also be expanded in the future.
    </p>
    <div class="row pt-5">
        <div class="col-md-2 col-8 pb-4">
            <img class="alipay" src="../../assets/img/AlipayPlus.png" alt="Visa Secure" />
        </div>
        <div class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/alipay.png" alt="MasterCard SecureCode" />
        </div>
        <div class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/alipayhk.png" alt="American Express SafeKey" />
        </div>
        <div class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/kakoapay.png" alt="Visa Secure" />
        </div>
        <div class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/gcash.png" alt="MasterCard SecureCode" />
        </div>
        <div class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/touchngo.png" alt="American Express SafeKey" />
        </div>
        <div class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/rabbitlinepay.png" alt="Visa Secure" />
        </div>
        <div class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/truemoney.png" alt="MasterCard SecureCode" />
        </div>
        <div class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/dana.jpg" alt="American Express SafeKey" />
        </div>
        <div class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/akulakuPaylater.png" alt="American Express SafeKey" />
        </div>
    </div>
</mat-dialog-content>