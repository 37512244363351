<form [formGroup]="form">
    <ng-container>
        <div class="row">
            <div class="col-lg-12 pb-3" *ngIf="gd?.oscConfig?.displayShippingAsBillingAddr">
                <input type="checkbox" id="sameAsShippingContactAddress" name="sameAsShippingContactAddress"
                    formControlName="sameAsShippingContactAddress" (change)="sameadrchkchange($event)" />
                <label class="ps-2" for="sameAsShippingContactAddress">{{addrLabel}}</label>
            </div>
            <div class="col-lg-4 pb-3">
                <label for="streetAddress1" class="control-label"><span class="required-asterisk">*</span>Street
                    Address 1</label>
                <input id="streetAddress1" name="streetAddress1" class="form-control form-control-md"
                    formControlName="streetAddress1" />
                <span *ngIf="
                    (form.controls['streetAddress1'].hasError('required') || form.controls['streetAddress1'].hasError('minLength')) &&
                    form.controls['streetAddress1'].touched
                  " class="text-danger">
                    You must enter your address.</span>
            </div>
            <div class="col-lg-4 pb-3">
                <label for="streetAddress2" class="control-label">Street Address 2</label>
                <input id="streetAddress2" name="streetAddress2" class="form-control form-control-md"
                    formControlName="streetAddress2" placeholder="(optional)" />
            </div>
            <div class="col-lg-4 pb-3">
                <label for="city" class="control-label"><span class="required-asterisk">*</span>City</label>
                <input id="city" name="city" class="form-control form-control-md" formControlName="city" />
                <span *ngIf="
                form.controls['city'].hasError('required') &&
                form.controls['city'].touched
              " class="text-danger">
                    You must enter your city.</span>
            </div>
            <div class="col-lg-4 pb-3">
                <label for="country" class="control-label"><span class="required-asterisk">*</span>Country</label>
                <select formControlName="country" class="form-select form-select-md" (change)="changeCountry()">
                    <option selected disabled value="">- Select -</option>
                    <option *ngFor="let country of countryList" [value]="country.id">{{country.name}}</option>
                </select>
                <span *ngIf="
                    (form.controls['country'].hasError('required') || form.controls['country'].hasError('minLength')) &&
                    form.controls['country'].touched
                  " class="text-danger">
                    You must select a country.</span>
            </div>
            <div class="col-lg-4 pb-3" *ngIf="selectedCountry === 'US' || selectedCountry === 'CA' ; else region">
                <label for="region" *ngIf="selectedCountry === 'US'" class="control-label"> <span
                        class="required-asterisk">*</span>State</label>
                <label for="province" *ngIf="selectedCountry === 'CA'" class="control-label"> <span
                        class="required-asterisk">*</span>Province</label>
                <select *ngIf="selectedCountry === 'US' || 'CA'" formControlName="region"
                    class="form-select form-select-md">
                    <option selected  value="">- Select -</option>
                    <option *ngFor="let region of regionList" [value]="region.id">{{region.name}}</option>
                </select>
                <span *ngIf="
                (form.controls['region'].hasError('required') || form.controls['region'].hasError('minLength')) &&
                form.controls['region'].touched
              " class="text-danger">
              You must select a {{selectedCountry === 'US' ? 'State': 'Province'}}.</span>
            </div>
            <ng-template #region>
                <div class="col-lg-4 pb-3">
                    <label for="stateProvince">State/Province</label>
                    <input id="region" name="region" class="form-control form-control-md" formControlName="region"
                        placeholder="(optional)" />
                </div>
            </ng-template>
            <div class="col-lg-4 pb-3" *ngIf="selectedCountry === 'US' || selectedCountry === 'CA'; else postal">
                <label for="zipcode" class="control-label"><span class="required-asterisk">*</span>{{selectedCountry ===
                    'US' ? 'Zip' : 'Postal'}}
                    Code</label>
                <input id="zipcode" name="zipcode" class="form-control form-control-md" formControlName="zipcode" />
                <span
                    *ngIf="
                    form.controls['zipcode'].hasError('required') && form.controls['zipcode'].touched"
                    class="text-danger">
                    You must enter your {{selectedCountry === 'US' ? 'Zip' : 'Postal'}} code.</span>
                <span *ngIf="form.controls['zipcode'].hasError('minlength')" class="text-danger">
                    {{selectedCountry === 'US' ? 'Zip' : 'Postal'}} Code must contain at least {{selectedCountry ===
                    'US' ? '5' : '6'}} numeric characters.</span>
                <span *ngIf="form.controls['zipcode'].hasError('maxlength')" class="text-danger">
                    {{selectedCountry === 'US' ? 'Zip' : 'Postal'}} Code max allowed 9 numeric characters.</span>
                    <span
                    *ngIf="form.controls['zipcode'].hasError('pattern')"
                    class="text-danger">
                    {{selectedCountry === 'US' ? 'Zip' : 'Postal'}} Code is invalid.</span>
            </div>
            <ng-template #postal>
                <div class="col-lg-4 pb-3">
                    <label for="billingPostalcode">
                        Zip/Postal Code
                    </label>
                    <input class="form-control form-control-md" type="text" name="zip-postalcode" id="zipcode"
                        maxlength="10" aria-label="Zip/Postal Code" placeholder="(optional)"
                        formControlName="zipcode" />
                </div>
            </ng-template>
            <div class="col-lg-4 pb-3">
                <label for="company" class="control-label">Company</label>
                <input class="form-control form-control-md" type="text" name="company" id="company" aria-label="company"
                    placeholder="(optional)" formControlName="company" />
            </div>
            <div class="col-lg-4 pb-3">
                <label for="phoneNumber" class="control-label">Phone Number
                  <ng-container *ngIf="isRequiredField('phoneNumber')">
                     <span class="required-asterisk">
                  *
                     </span>
                  </ng-container>
                </label>
                <input class="form-control form-control-md" type="tel" name="phoneNumber" id="phoneNumber"
                    [placeholder]="isRequiredField('phoneNumber')?'':'(optional)'" formControlName="phoneNumber" />
                <span *ngIf="form.controls['phoneNumber'].hasError('pattern') &&
                    form.controls['phoneNumber'].touched
                  " class="text-danger">
                    Phone Number is invalid.</span>

              <span *ngIf="isRequiredField('phoneNumber') &&
                    form.controls['phoneNumber'].touched && !form.controls['phoneNumber'].value
                  " class="text-danger">
                    Phone Number is required.</span>
            </div>
            <div class="col-lg-4 pb-3">
                <label for="email" class="control-label">Email
                  <ng-container *ngIf="isRequiredField('email')">
                    <span class="required-asterisk">
                    *</span>
                  </ng-container>
                </label>
                <input class="form-control form-control-md" type="email" name="email" id="email"
                    [placeholder]="isRequiredField('email')?'':'(optional)'" formControlName="email" />
                <span *ngIf="form.controls['email'].hasError('pattern') &&
                    form.controls['email'].touched
                  " class="text-danger">
                    Email Address is invalid.</span>

              <span *ngIf="isRequiredField('email') &&
              form.controls['email'].touched && !form.controls['email'].value
              " class="text-danger">
              Email Address is required.</span>

            </div>
        </div>
    </ng-container>
</form>
