<div id="shipping-contact-info" class="card rounded-card receiving-information"
    *ngIf="gd?.oscConfig?.displayShippingFlds" tabindex="0" role="document">
    <div class="card-body">
        <span class="shipping__title_infor">Shipping/Contact Information</span>
        <div class="shipping__text_address">
            <span *ngIf="orderData?.shippingAddress?.firstName">{{orderData?.shippingAddress?.firstName}}</span>&nbsp;

            <span *ngIf="orderData?.shippingAddress?.lastName">{{orderData?.shippingAddress?.lastName}}</span>
        </div>
        <div *ngIf="orderData?.shippingAddress?.company" class="shipping__text_address">{{orderData?.shippingAddress?.company}}</div>
        <div class="shipping__text_address">{{orderData?.shippingAddress?.addressLine1}}</div>
        <div class="shipping__text_address">{{orderData?.shippingAddress?.addressLine2}}</div>
        <div class="shipping__text_address">{{orderData?.shippingAddress?.city}} {{orderData?.shippingAddress.region}}</div>
        <div class="shipping__text_address">{{orderData?.shippingAddress?.postalCode}}</div>
        <div class="shipping__text_address">{{orderData?.shippingAddress?.country}}</div>
        <div class="mt-4 shipping__text_address" *ngIf="orderData?.shippingContact?.phoneNumber">
            <span aria-label="phone number">
                <fa-icon [icon]="faPhone"></fa-icon>
            </span> {{orderData?.shippingContact?.phoneNumber}}
        </div>
        <div *ngIf="orderData?.shippingContact?.email" class="shipping__text_address">
            <span aria-label="email address">
                <fa-icon [icon]="faEnvelope"></fa-icon>
            </span> {{orderData?.shippingContact?.email}}
        </div>
        <div class="mt-4 shipping__text_font_types">
            <span>This is the name and address of the person who will receive the product/service (e.g., the person who
                will be taking a test or is requesting scores); or, if you ordered items to be shipped, they will be
                sent to this address.</span>
        </div>
    </div>
</div>