<div class="row pt-5 mb-4" tabindex="0">
    <div class="col-md-12 mb-2 accepted">
        Accepted Payment Types
    </div>
    <div class="col-md-12 row" *ngIf="gd?.oscConfig?.ccPaymentEnabled">
        <div class="col-md-1 col-4 d-flex justify-content-center" *ngIf="gd?.oscConfig?.visaEnabled && showCreditcardType('Visa')">
            <img class="logos-xsmall" src="../../assets/img/epms-payment-cardsvisa-copy.png" alt="Visa" />
        </div>
        <div class="col-md-1 col-4 d-flex justify-content-center" *ngIf="gd?.oscConfig?.masterCardEnabled && showCreditcardType('MasterCard')">
            <img class="logos-xsmall" src="../../assets/img/epms-payment-cardsmastercard-copy.png" alt="MasterCard" />
        </div>
        <div class="col-md-1 col-4 d-flex justify-content-center" *ngIf="gd?.oscConfig?.amexEnabled && showCreditcardType('Amex')">
            <img class="logos-xsmall" src="../../assets/img/epms-payment-cardsamex-copy.png" alt="American Express" />
        </div>
        <div class="col-md-1 col-4 d-flex justify-content-center" *ngIf="gd?.oscConfig?.discoverEnabled && showCreditcardType('Discover')">
            <img class="logos-xsmall" src="../../assets/img/epms-payment-cardsdiscover-copy.png" alt="Discover" />
        </div>
        <div class="col-md-1 col-4 d-flex justify-content-center" *ngIf="gd?.oscConfig?.jcbEnabled && showCreditcardType('JCB')">
            <img class="logos-xsmall" src="../../assets/img/epms-payment-cardsjcb-copy.png" alt="JCB" />
        </div>
        <div class="col-md-1 col-4 d-flex justify-content-center" *ngIf="gd?.oscConfig?.unionPayEnabled && showCreditcardType('UnionPay')">
            <img class="logos-medium" src="../../assets/img/epms-payment-cardsunionpay-copy.png" alt="UnionPay" />
        </div>
        <div class="col-md-1 col-4 d-flex justify-content-center" *ngIf="gd?.oscConfig?.dinersClubEnabled&& showCreditcardType('Diners Club')">
            <img class="logos-medium" src="../../assets/img/epms-payment-cardsdinersclub-copy.png" alt="Diners Club" />
        </div>
        <div class="col-md-1 col-4 d-flex justify-content-center pt-3" *ngIf="gd?.oscConfig?.rupayGlobalEnabled && showCreditcardType('RuPay Global')">
            <img class="logos-xsmall" src="../../assets/img/epms-payment-cardsrupay-copy.png" alt="RuPay" />
        </div>
        <div class="col-md-1 col-4 d-flex justify-content-center pt-3" *ngIf="paypalEnabled">
            <!-- PayPal Logo -->
            <img class="logos-mediumL" src="../../assets/img/PP_Acceptance_Marks_for_LogoCenter_150x94.png"
                alt="Paypal" />
            <!-- PayPal Logo -->
        </div>
        <div class="ms-3 col-md-4 col-10 d-flex justify-content-center Alipay-Acceptance" *ngIf="alipayEnabled">
            <img class="" src="../../assets/img/AlipayPlusAcceptance.png" alt="Alipay+" />
        </div>
        <!-- <div class="col-md-1 col-2 d-flex justify-content-center ms-2 pt-3 text-nowrap Alipay-Acceptance" *ngIf="alipayEnabled">
            ....& more
        </div> -->
    </div>
