<div *ngIf="orderData?.lineItems?.length>0" class="card rounded-card mb-4">
    <div class="card-body card-body-responsive">
        <div class="checkout__dektop_mini">
            <table [ngClass]="orderData.lineItems.length > 2
                    ?
                    'table table-responsive-sm scroll__on_line_items':'table table-responsive-sm scroll__on_line_items__no_scroll'">
                <tr class="border_bottom_header table__rows_align">
                    <th class="no-border first__th_header_order_summary">
                        <h2 class="first__table_header_val">Order Summary</h2>
                    </th>
                    <th class="no-border text-end second__th_header">
                        <span class="text__font_header_td">Unit Price</span>
                    </th>
                    <th class="no-border text-end third__th_header">
                        <span class="text__font_header_td">Tax</span>
                    </th>
                    <th class="no-border text-end fourth__th_header">
                        <span class="text__font_header_td">Total</span>
                    </th>
                </tr>
                <tr class="dotted table__rows_align tr__dotted_bottom" *ngFor="let lineItem of orderData.lineItems">
                    <th class="no-border first__th_header_order_summary">
                        <div class="description__text_line_item description_with_margin">{{lineItem?.productName}}</div>
                        <div class="line__item_quantity description_with_margin">
                          <span class="ps-2">Qty:</span>
                          <span class="line__item_qty_margin">{{lineItem?.quantity}}</span>
                        </div>
                    </th>
                    <th class="no-border text-end second__th_header">
                        <span class="text__font_header_td">{{lineItem?.unitPrice | number : '1.2-2'}}</span>
                    </th>
                    <th class="no-border text-end third__th_header">
                        <span class="text__font_header_td">{{lineItem?.taxAmount | number : '1.2-2'}}</span>
                    </th>
                    <th class="no-border text-end fourth__th_header">
                        <span class="text__font_header_td">{{lineItem?.total | number : '1.2-2'}}</span>
                    </th>
                </tr>
                <tr class="sub-total table__rows_align">
                    <td class="first-col borderless top ps-0">
                        <span class="text__font_header_td">Subtotal</span>
                    </td>
                    <td class="borderless top pe-0" colspan="3">
                        <span class="text__font_header_td">{{orderData.amountDetail.subtotalAmount | number : '1.2-2'}}</span>
                    </td>
                </tr>
                <tr class="sub-total table__rows_align">
                    <td class="first-col borderless bottom ps-0">
                        <span class="text__font_header_td">Tax</span>
                    </td>
                    <td class="borderless bottom pe-0" colspan="3">
                        <span class="text__font_header_td">{{orderData.amountDetail.taxAmount | number : '1.2-2'}}</span>
                    </td>
                </tr>
            </table>
        </div>

        <div class="checkout__mobile__view">
            <table [ngClass]="orderData.lineItems.length > 2
                    ?
                    'table table-responsive-sm scroll__on_line_items':'table table-responsive-sm scroll__on_line_items__no_scroll'">
                <tr class="border_bottom_header table__rows_align">
                    <th class="no-border first__th_header_order_summary">
                        <h2 class="first__table_header_val">Order Summary</h2>
                    </th>
                    <th class="no-border text-end second__th_header"><span class="text__font_header_td">Unit Price</span></th>
                    <th class="no-border text-end third__th_header"><span class="text__font_header_td">Tax</span></th>
                    <th class="no-border text-end fourth__th_header"><span class="text__font_header_td">Total</span></th>
                </tr>
                <tr class="table__rows_align tr__dotted_bottom" *ngFor="let lineItem of orderData.lineItems">
                    <th class="no-border first__th_header_order_summary">
                        <div class="description__text_line_item">{{lineItem?.productName}}</div>
                        <div class="line__item_quantity">
                          <span class="ps-2">Qty:</span>
                          <span class="line__item_qty_margin">{{lineItem?.quantity}}</span>
                        </div>
                    </th>
                    <th class="no-border text-end second__th_header">
                        <span class="text__font_header_td">{{lineItem?.unitPrice | number : '1.2-2'}}</span>
                    </th>
                    <th class="no-border text-end third__th_header">
                        <span class="text__font_header_td">{{lineItem?.taxAmount | number : '1.2-2'}}</span>
                    </th>
                    <th class="no-border text-end fourth__th_header">
                        <span class="text__font_header_td">{{lineItem?.total | number : '1.2-2'}}</span>
                    </th>
                </tr>
                <tr class="sub-total table__rows_align">
                    <td class="first-col borderless top ps-0">
                        <span class="text__font_header_td">Subtotal</span>
                    </td>
                    <td class="borderless top pe-0" colspan="3">
                        <span class="text__font_header_td">{{orderData.amountDetail.subtotalAmount | number : '1.2-2'}}</span>
                    </td>
                </tr>
                <tr class="sub-total table__rows_align">
                    <td class="first-col borderless bottom ps-0">
                        <span class="text__font_header_td">Tax</span>
                    </td>
                    <td class="borderless bottom pe-0" colspan="3">
                        <span class="text__font_header_td">{{orderData.amountDetail.taxAmount | number : '1.2-2'}}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<app-merchant-response></app-merchant-response>
