import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ISessionAuth } from '../models/paymentjs-models';
import { CurrencyConversionResponse } from '../models/payment';
import { GlobalAppData } from '../models/global-app-data';
import { Observable } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';

declare const threatmetrix: any;
@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  paymentUiUrl = environment.PAYMENT_UI;
  constructor(private http: HttpClient, private gd: GlobalAppData) { }
  httpHeaders = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  // variables to cache the response and observable
  private tokenCacheObservable: Observable<ISessionAuth>;
  private dccCacheObservable: Observable<CurrencyConversionResponse>;

  /**
   * This method makes call to get token details.
   * if the response already exists returns that, if not it will return cached observable if exists.
   * else it will make api call to get the token details
   *
   * @param orderTranscationId order transaction id
   * @param cardType user selected card type
   * @param jsCardType fiserv return card type
   * @returns ISessionAuth as Promise
   */
  getCreditCardSessionDetails(orderTranscationId: string, cardType: string, jsCardType: string): Promise<ISessionAuth> {
    let observable: Observable<ISessionAuth>;
    console.debug(`inside the Tcache res1`);
    const reqaPrams = {
        sourceType: this.gd.sourceType,
        cardType: cardType,
        jsCardType: jsCardType
    };
    this.tokenCacheObservable = this.http.get<ISessionAuth>(this.paymentUiUrl + '/epms/token/' + orderTranscationId, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params: reqaPrams
      }).pipe(
          tap( res => res),
          share(), finalize(() => this.tokenCacheObservable = null));
      observable = this.tokenCacheObservable;
    return observable.toPromise();
  }

  getFraudScore(orderTranscationId: string): void {
    threatmetrix.profile('h.online-metrix.net', 'dm1pufbd', orderTranscationId);
  }

  /**
   * This method makes call to get dcc details.
   * if the response already exists returns that, if not it will return cached observable if exists.
   * else it will make api call to get the dcc details
   *
   * @param orderTranscationId order transaction id to get dcc for
   * @returns CurrencyConversionResponse as Observable
   */
  getDccDetails(orderTranscationId: string) {
    console.log(' [card service dcc] ',orderTranscationId)
    let observable: Observable<CurrencyConversionResponse>;
    this.dccCacheObservable = this.http.get<CurrencyConversionResponse>(this.paymentUiUrl + '/epms/dcc/' + orderTranscationId, this.httpHeaders)
      .pipe(
          tap( res => res),
          share(), finalize(() => this.dccCacheObservable = null));
      observable = this.dccCacheObservable;
    return observable;
  }

  postCcDetailsToStub(orderTranscationId: string, reqBody: any) {
    return this.http.post<boolean>(this.paymentUiUrl + '/epms/stub/' + orderTranscationId, reqBody, this.httpHeaders);
  }
}
