<div class="light-blue rounded-card desktop__view" tabindex="0">
        <div class="card-body p-3">
                <div class="row">
                        <div class="ps-4 pt-2 col-lg-6 col-sm-12">
                                <span style="font-family: Arial, Helvetica, sans-serif; font-size: 24px;">Order Total</span>
                        </div>
                        <div class="pe-4 col-lg-6 col-sm-12 justify-content-end total-number align-items-center text-lg-end txt-md-end">
                                <div class="one-twenty">
                                        <span style="font-family: Arial, Helvetica, sans-serif; font-size: 16px;">Amount (USD)</span>
                                </div>
                                <div class="amount"><span>${{orderData?.amount | number : '1.2-2'}}</span></div>
                        </div>
                </div>
        </div>
</div>

<div class="light-blue rounded-card ipad__mobile_view" tabindex="0">
        <div class="card-body p-3">
                <div class="order__total__d_flex">
                        <div>
                                <span class="title__order_total">Order Total</span>
                        </div>
                        <div>
                                <div class="one-twenty">
                                        <span class="order__total_amount">Amount (USD)</span>
                                </div>
                                <div class="amount"><span>${{orderData?.amount | number : '1.2-2'}}</span></div>
                        </div>
                </div>
        </div>
</div>